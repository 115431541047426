import resume from "../assets/pdf/resume.pdf";
import profile from "./../assets/png/profile.png";

export const headerData = {
  name: "Abdullahi Yusuf",
  title: "Softare Engineer",
  desciption: [
    "also known as <strong>abdullahicyc</strong> on the internet, I'm a software engineer based in <em>Mogadishu</em>.",
    "I graduated with a B.Sc in computer engineering from <a class='link' href='https://hu.edu.so' target='_blank'>Hormuud University</a> and am currently working for <a class='link' href='https://safarione.ca' target='_blank'>@safarione</a> building fintech apps."
  ],
  image: profile,
  resumePdf: resume
};
