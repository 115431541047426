import React, { createContext, useEffect, useState } from "react";

import { themeData } from "../data/themeData";

export const ThemeContext = createContext();

function ThemeContextProvider(props) {
  // eslint-disable-next-line
  const [theme, setTheme] = useState(themeData.theme);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const setHandleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (matchMedia("(prefers-color-scheme: dark)").matches) {
      // setTheme(themeData.dark);
    }
  }, []);

  const value = { theme, drawerOpen, setHandleDrawer };
  return <ThemeContext.Provider value={value}>{props.children}</ThemeContext.Provider>;
}

export default ThemeContextProvider;
